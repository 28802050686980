<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<h5 class="mb0">
								<strong>Registro de citas</strong>
							</h5>
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<insertOrUpdateQuote />
				</div>
			</div>
		</a-spin>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import insertOrUpdateQuote from '@/components/insertOrUpdateQuote'

export default {
	name: 'NewQuoteView',
	components: {
		insertOrUpdateQuote,
	},
	computed: {
		...mapGetters('quotes', ['spinnerLoader', 'spinnerLoaderLabel']),
	},
}
</script>