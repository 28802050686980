var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Tipo de servicio"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'service_type_id',
						{
							rules: [
								{
									required: true,
									message: 'Selecciona una opción',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'service_type_id',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Selecciona una opción',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Selecciona una opción"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("Servicio")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("Específico")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("Reparación")]),_c('a-select-option',{attrs:{"value":"4"}},[_vm._v("Diagnóstico")])],1)],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Servicio"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'service_id',
						{
							rules: [
								{
									required: true,
									message: 'Selecciona una opción',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'service_id',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Selecciona una opción',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Selecciona una opción","show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption}},_vm._l((_vm.serviceAdministrationList),function(service,index){return _c('a-select-option',{key:index,attrs:{"value":service.id}},[_vm._v(_vm._s(service.service_name))])}),1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }