<template>
	<a-form class="mb-4" :form="form" @submit="handleSubmit">
		<div class="row">
			<div class="col-md-4">
				<a-form-item label="Tipo de servicio">
					<a-select
						placeholder="Selecciona una opción"
						v-decorator="[
							'service_type_id',
							{
								rules: [
									{
										required: true,
										message: 'Selecciona una opción',
									},
								],
							},
						]"
					>
						<a-select-option value="1">Servicio</a-select-option>
						<a-select-option value="2">Específico</a-select-option>
						<a-select-option value="3">Reparación</a-select-option>
						<a-select-option value="4">Diagnóstico</a-select-option>
					</a-select>
				</a-form-item>
			</div>
			<div class="col-md-4">
				<a-form-item label="Servicio">
					<a-select
						placeholder="Selecciona una opción"
						v-decorator="[
							'service_id',
							{
								rules: [
									{
										required: true,
										message: 'Selecciona una opción',
									},
								],
							},
						]"
						show-search
						option-filter-prop="children"
						:filter-option="filterOption"
					>
						<a-select-option v-for="(service, index) in serviceAdministrationList" :value="service.id" :key="index">{{ service.service_name }}</a-select-option>
					</a-select>
				</a-form-item>
			</div>
		</div>
	</a-form>
</template>
<script>
//
import { mapGetters } from 'vuex'

export default {
	name: 'InsertOrUpdateQuoteComponent',
	computed: {
		...mapGetters('serviceAdministration', ['serviceAdministrationList']),
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
				}
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>